<template>
    <div class="form-container">
        <div>
            <img src="@/assets/authen247.png" alt="Title" class="title-image"/>
        </div>
        <div class="header" >
            <div class="verified_data">
                {{ header_data }}
            </div>
            <div class="header-text" v-if="!isValidData"> To begin your benefit claim, please fill out the designated form.</div>
        </div>

        <div class="card">

            <form @submit.prevent="submitForm">
                <!-- <div class="form-group">
                    <label for="line_id">LINE ID:</label>
                    <input type="text" v-model="line_id" id="line_id" :readonly="true" />
                </div> -->
                <div class="form-group">
                    <label for="line_name">Line:<span class="required">*</span></label>
                    <input type="text" v-model="line_name" id="line_name" :readonly="true" :class="{'invalid-input': !validLineName }"/>
                </div>
                <div class="form-group">
                    <label for="name">Name:<span class="required">*</span></label>
                    <input type="text" v-model="name" id="name" :class="{'invalid-input': !validName }"/>
                </div>
                <div class="form-group">
                    <label for="telephone">Tel:<span class="required">*</span></label>
                    <input type="text" v-model="telephone" id="telephone"  :class="{'invalid-input': !validTelephone }"/>
                </div>
                <div class="form-group">
                    <label for="email">Email:</label>
                    <input type="text" v-model="email" id="email" />
                </div>
                <div class="form-group">
                    <label for="date_of_birth">DOB:</label>
                    <input type="date" v-model="formattedDateOfBirth" id="date_of_birth" />
                </div>
            </form>
        </div>
        <div class="bottom">
                <button class="style-button" @click="submitForm"> 
                    {{ button_text }}
                </button>
            <div class="bottom-text"> This purchase earn you <strong style="color: #21334C;">{{ product_point }}+</strong> points</div>
        </div>
    </div>
</template>


<script>
import apiClient from '../services/api.js';
import liff from '@line/liff';

export default {
    name: 'LineCallBackPage',
    data() {
        return {
            phone_number: null,
            qrCode: null,
            state:null,
            code:null,
            line_id: null,
            line_name: null,
            name:null,
            email: null,
            telephone: null,
            date_of_birth: null,
            header_data: null,
            isValidData: false,
            validfield: true,
            // validEmail: true,
            validLineName: true,
            validName: true,
            validTelephone: true,
            // validDob: true,
            button_text: null,
        };
    },
    computed: {
        formattedDateOfBirth: {
        get() {
            return this.date_of_birth ? this.date_of_birth.split('T')[0] : null;
        },
        set(value) {
            this.date_of_birth = value;
        }
        }
    },
    async mounted() {
        try {
            await this.initializeLiff();

            const urlParams = new URLSearchParams(window.location.search);
            this.qrCode = urlParams.get('qrCode');

            
            if (liff.isLoggedIn()) {
                const profile = await liff.getProfile();

                try {
                    const response = await apiClient.post('/line/user-info', {
                        profile: profile,
                        qrCode: this.qrCode,
                    });

                    this.line_id = response.data.line_id;
                    this.line_name = response.data.line_name;
                    this.name = response.data.name;
                    this.email = response.data.email;
                    this.telephone = response.data.telephone;
                    this.date_of_birth = response.data.date_of_birth ? response.data.date_of_birth.split('T')[0] : null;
                    this.isValidData = response.data.is_valid;
                    this.header_data = response.data.header_data;
                    this.product_point = response.data.product_point;

                    if ( this.isValidData ){
                        this.button_text = "Confirm"
                    } else {
                        this.button_text = "Sign Up"
                    }

                } catch (error) {
                    console.error('Error exchanging code for token:', error);
                }
            } 
        } catch (error) {
            console.error('Error in LIFF initialization or login:', error);
        }

    },
    methods: {
        async initializeLiff() {
            try {
                await liff.init({ liffId: process.env.VUE_APP_LIFF_ID });
            } catch (error) {
                console.error('LIFF Initialization failed:', error);
            }
        },
        navigateTo(page, response_data) {
            const accumulated_point = response_data.product_credit_point;
            const total_accumulated_point = response_data.total_accumulated_point;
            const name = response_data.name;
            this.$router.push({ 
                path: `/${page}`,
                query: { accumulated_point, total_accumulated_point, name }  
            });
        },
        submitForm() {
            this.validfield = true; 
            // this.validEmail = true;
            this.validLineName = true;
            this.validName = true;
            this.validTelephone = true;
            // this.validDob = true; 
            let missingFields = [];

            if (!this.line_name) {
                this.validfield = false;
                this.validLineName = false;
                missingFields.push("Line");
            }
            if (!this.name) {
                this.validfield = false;
                this.validName = false;
                missingFields.push("Name");
            }
            // if (!this.email) {
            //     this.validfield = false;
            //     this.validEmail = false;
            //     missingFields.push("Email");
            // }
            if (!this.telephone) {
                this.validfield = false;
                this.validTelephone = false;
                missingFields.push("Tel");
            }
            // if (!this.formattedDateOfBirth) {
            //     this.validfield = false;
            //     this.validDob = false;
            //     missingFields.push("DOB");
            // }

            if (!this.validfield) {
                alert(`You are not able to do any redemption without these info: ${missingFields.join(", ")}`);
                return;
            }

            apiClient.post('purchase', {
                qr_code: this.qrCode,
                is_valid_data: this.isValidData,
                line_id: this.line_id,
                line_name: this.line_name,
                name: this.name,
                email: this.email,
                telephone: this.telephone,
                product_point: this.product_point,
                date_of_birth: this.date_of_birth,
            })
            .then(response => {
                this.navigateTo('accumulated-point', response.data);
            })
            .catch(error =>{
                if (error.response){
                    this.header_data = error.response.data.msg;
                }
            });
        },
    },
};
</script>

<style scoped>
    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #E9EBED;
    }

    .title-image{
        max-width: 35%;
        height: auto;
        border-radius: 4px;
    }

    .header {
        margin-bottom: 20px; 
        text-align: center;
        width: 300px;
    }

    .header-text {
        font-size: 10px;
        color: #7A8594;
    }

    .card {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 300px;
    }

    .verified_data{
        color: #21334C;
        font-weight: bold;
        font-style: italic;
        font-family: "Times New Roman", serif;
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 20px;
    }

    .form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    label {
        margin-right: 10px; 
        text-align: start;
        font-style: italic;
        width: 20%;
        color: rgb(142, 139, 139);
        font-family: "Times New Roman", serif;
        font-size: 15px;
    }

    input {
        width: 80%;
        padding: 8px;
        box-sizing: border-box;
        border: none;
        border-radius: 4px;
        outline: none;
        text-align: end;
        font-family: "Times New Roman", serif;
        font-size: 15px;
        background-color: #f3faf3;
    }

    .invalid-input {
        border: 1px solid red;
    }

    input:focus {
        outline: none; 
        box-shadow: none; 
    }

    .style-button {
        margin-top: 20px;
        margin-bottom: 10px;
        background-color: #21334C; 
        font-weight: 900;
        color: white; 
        padding: 10px;
        border: none;
        border-radius: 50px;
        font-size: 20px;
        cursor: pointer;
        width: 100%;
        display: flex; 
        align-items: center; 
        justify-content: center;
    }

    .bottom {
        width: 340px;
    }

    .bottom-text {
        font-size: 15px;
        color: #7A8594;
    }

    .required {
        color: red;
        font-weight: bold;
        margin-left: 4px;
    }

</style>
