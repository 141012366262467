<template>
    <div class="form-container">
        <div>
            <img src="@/assets/authen247.png" alt="Title" class="title-image"/>
        </div>
        <div class="header" >
            <div class="verified_data">
                {{ header_data }}
            </div>
            <div class="header-text"> The registration has completed.</div>
        </div>

        <div class="card">
            <div class="product-image-container">
                <img src="@/assets/congrat.jpg" alt="Product Image" class="product-image">
            </div>
            <div class="verified_data">
                {{ name }}
            </div>
            <div class="bottom-text"> You currently have <strong style="color: #21334C;">{{ total_accumulated_point }}</strong> points</div>
        </div>
        <!-- <div class="bottom">
            <div class="header-text"> Explore what benefits your points can unlock by clicking the button below.</div>
            <button class="style-button"> 
                Discover Benefits
            </button>
        </div> -->
    </div>
</template>


<script>
    export default {
        data() {
            return {
                total_accumulated_point: this.$route.query.total_accumulated_point,
                name: this.$route.query.name,
                header_data: 'Congratulation',
            };
        },
        methods: {
            navigateTo(page){
                this.$router.push({ path: `/${page}` });
            }
        },
    };
</script>

<style scoped>
    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #E9EBED;
      margin: 0; /* Ensure no extra spacing is added */
      box-sizing: border-box;
    }

    .title-image{
        max-width: 35%;
        height: auto;
        border-radius: 4px;
    }

    .header {
        margin-bottom: 20px; 
        text-align: center;
        width: 300px;
    }

    .header-text {
        font-size: 10px;
        color: #7A8594;
    }

    .card {
      background: white;
      padding-bottom: 20px;
      padding-top: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 300px;
    }

    .product-image-container {
        margin-bottom: 30px;
        text-align: center;
    }

    .product-image {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .verified_data{
        color: #21334C;
        font-weight: bold;
        font-style: italic;
        font-family: "Times New Roman", serif;
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 20px;
    }

    .style-button {
        margin-top: 20px;
        margin-bottom: 10px;
        background-color: #E9EBED; 
        font-weight: 900;
        color: #21334C; 
        padding: 10px;
        border-radius: 50px;
        font-size: 20px;
        cursor: pointer;
        width: 100%;
        display: flex; 
        align-items: center; 
        justify-content: center;
    }

    .bottom {
        margin-top: 20px;
        width: 240px;
    }

    .bottom-text {
        font-size: 15px;
        color: #7A8594;
    }
</style>